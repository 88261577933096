<template>
  <div>
    <p>
      <form-input
        :value="value"
        :has-margin="false"
        :has-error="$v.value.$invalid && hasPasswordBlurred"
        error-message="Podaj hasło"
        :label="label"
        :name="name"
        type="password"
        autocomplete="off"
        v-on="listeners"
      />
    </p>
    <ul v-if="value" class="password-validation">
      <li
        v-for="{ text, isValid } in passwordStrengthRules"
        :key="text"
        class="password-validation__item"
        :class="{
          'password-validation__item--is-valid': isValid,
        }"
      >
        {{ text }}
      </li>
    </ul>
  </div>
</template>

<script>
import get from 'lodash/get';
import PasswordStrength from 'tai-password-strength/lib/password-strength';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import FormInput from '@/vue/components/user-attributes-form/form-input.vue';

const strengthTester = new PasswordStrength();

export default {
  name: 'PasswordInput',

  components: { FormInput },

  mixins: [validationMixin],

  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: false,
      default: 'hasło',
    },

    autocomplete: {
      type: String,
      required: false,
      default: null,
    },
  },

  validations: {
    value: {
      required,
    },
  },

  data: () => ({
    hasPasswordBlurred: false,
  }),

  computed: {
    passwordStrengthRules() {
      const { passwordLength, charsets } = strengthTester.check(this.value);

      return [
        {
          text: 'Hasło musi zawierać co najmniej 8 znaków',
          isValid: passwordLength >= 8,
        },
        {
          text: 'Hasło musi zawierać liczbę',
          isValid: get(charsets, 'number', false),
        },
        {
          text: 'Hasło musi zawierać wielką literę',
          isValid: get(charsets, 'upper', false),
        },
        {
          text: 'Hasło musi zawierać małą literę',
          isValid: get(charsets, 'lower', false),
        },
        {
          text: 'Hasło musi zawierać znak specjalny',
          isValid:
            get(charsets, 'symbol', false) ||
            get(charsets, 'punctuation', false),
        },
      ];
    },

    isPasswordValid() {
      return this.passwordStrengthRules.every(({ isValid }) => isValid);
    },

    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        keyup: this.onKeyUp,
      };
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },

    onKeyUp($event) {
      this.$emit('keyup', $event);
      this.$emit('validation-status-change', { isValid: this.isPasswordValid });
    },

    onBlur($event) {
      this.hasPasswordBlurred = true;
      this.$emit('blur', $event);
    },
  },
};
</script>
