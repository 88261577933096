<template>
  <form @submit.prevent="onSubmit">
    <p v-if="isError" class="sign-up__error">
      {{ errorMessage }}
    </p>
    <p>
      <form-input
        v-model="$v.email.$model"
        label="e-mail"
        name="email"
        error-message="Podaj prawidłowy adres e-mail"
        :has-error="$v.email.$error && hasEmailBlurred"
        @blur="onEmailBlur"
      />
    </p>
    <password-input
      v-model="$v.password.$model"
      label="Hasło"
      @validation-status-change="onValidationStatusChange"
    />
    <p class="sign-up__submit">
      <button
        class="button"
        type="submit"
        :disabled="!isFormValid"
        :class="{
          'button--loading': isLoading,
        }"
      >
        Załóż konto
      </button>
    </p>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import FormInput from '@/vue/components/user-attributes-form/form-input.vue';
import PasswordInput from '@/vue/components/password-input/password-input.vue';

export default {
  name: 'SignUp',

  components: { FormInput, PasswordInput },

  mixins: [validationMixin],

  props: {
    redirectOnSuccess: {
      type: String,
      required: false,
      default: null,
    },
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  data: () => ({
    hasEmailBlurred: false,
    email: '',
    password: '',
    isError: false,
    isLoading: false,
    errorMessage: '',
    isPasswordValid: false,
  }),

  computed: {
    isFormValid() {
      return !this.$v.email.$invalid && this.isPasswordValid;
    },
  },

  methods: {
    ...mapActions('user', ['signUp', 'signIn']),

    onEmailBlur() {
      this.hasEmailBlurred = true;
    },

    onValidationStatusChange({ isValid }) {
      this.isPasswordValid = isValid;
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        this.isError = false;
        await this.signUp({
          email: this.email,
          password: this.password,
        });
        await this.signIn({
          email: this.email,
          password: this.password,
        });

        if (this.redirectOnSuccess) {
          this.$router.push({
            name: this.redirectOnSuccess,
            query: {
              user: this.email,
            },
          });
        }
      } catch (error) {
        console.log('Unable to sign up', error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    handleError(e) {
      this.isError = true;

      const responses = [
        {
          code: 'UsernameExistsException',
          message: 'Konto o takim loginie już istnieje',
        },
      ];

      const genericMessage =
        'Wystąpił błąd. Prosimy o kontakt na: info@fotoregiony.pl';
      const found = responses.find(({ code }) => code === e.code);
      this.errorMessage = found ? found.message : genericMessage;
    },
  },
};
</script>
